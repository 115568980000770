<template>
    <v-menu v-model="open" offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                ref="wppButton"
                icon
                :color="getIconColor"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-text style="min-width: 260px; max-width: 320px;">
                <v-list style="text-align: center;">
                    <div v-if="wppConected" class="ma-3">
                        <div v-if="whatsappBotActive">
                            <div class="ml-2">
                                <v-chip
                                    color="#4CAF50"
                                    text-color="white"
                                >
                                    <v-icon left>
                                        mdi-check-circle-outline
                                    </v-icon>
                                    Robô ativo
                                </v-chip>
                            </div>

                            <v-btn
                                width="200"
                                class="mt-4"
                                color="error"
                                text
                                small
                                @click="whatsappBotActive = false"
                                :loading="connectionLoading"
                            >
                                Desativar
                            </v-btn>
                        </div>

                        <div v-else>
                            <div class="ml-2">
                                <v-chip
                                    color="error"
                                    outlined
                                >
                                    <v-icon left>
                                        mdi-circle-medium
                                    </v-icon>
                                    Robô inativo
                                </v-chip>
                            </div>

                            <v-btn
                                width="200"
                                class="mb-2 mt-4"
                                color="success"
                                @click="whatsappBotActive = true"
                                :loading="connectionLoading"
                            >
                                Ativar
                            </v-btn>
                        </div>

                        <div class="ml-4 mt-2">
                            {{ userWhatsapp }} - {{ userName }}
                        </div>
                    </div>

                    <div v-if="!wppConected && !wppQrBase64 && !pairingCode" style="display: flex; align-items: center;">
                        <v-progress-circular
                            indeterminate
                            color="blue lighten-1"
                            :size="50"
                        />
                        <span class="text-h6 ml-4">Verificando status da conexão...</span>
                    </div>

                    <v-btn
                        v-if="!wppConected && wppInstanceKey && wppQrBase64 && !pairingCode"
                        class="ma-2"
                        color="primary"
                        text
                        @click="connectWithPairingCode"
                        :loading="pairingCodeLoading"
                        small
                    >
                        Conectar com número de telefone
                    </v-btn>

                    <v-btn
                        v-if="!wppConected && wppQrBase64 && !pairingCode"
                        class="ma-2"
                        color="success"
                        @click="connectWpp"
                        :loading="qrCodeLoading"
                    >
                        <v-icon>mdi-refresh</v-icon>
                        Atualizar QrCode
                    </v-btn>

                    <v-btn
                        v-if="!wppConected && wppInstanceKey && pairingCode"
                        class="ma-2"
                        color="success"
                        @click="refreshPairingCode"
                        :loading="refreshPairingCodeLoading"
                    >
                        <v-icon>mdi-refresh</v-icon>
                        Atualizar Código
                    </v-btn>

                    <v-btn
                        v-if="wppConected && usuario.tipo === 'suporte'"
                        text
                        outlined
                        width="200"
                        class="ma-2"
                        color="error"
                        @click="deleteWppInstance"
                        :loading="connectionLoading"
                    >
                        Desconectar
                    </v-btn>

                    <v-btn
                        v-if="wppInstanceKey && !wppConected && pairingCode"
                        @click="conectWithQrCode"
                        text
                        small
                        color="primary"
                        class="ma-2"
                    >
                        Conectar com QR Code
                    </v-btn>

                    <div v-if="wppInstanceKey && wppQrBase64 && !wppConected" style="color: #444;">
                        <img
                            v-if="!pairingCode"
                            width="220"
                            height="220"
                            :src="wppQrBase64"
                        />
                        <v-otp-input
                            v-if="pairingCode"
                            readonly
                            length="8"
                            :value="pairingCode"
                            class="mx-2"
                        />
                        <div style="text-align: left; font-size: 12px; margin: 0px 20px 10px 10px;">
                            <div><b>Siga as instruções:</b></div>
                            <ol v-if="connectType === 'pairingcode'" class="mt-4">
                                <li>Abra o WhatsApp no seu celular.</li>
                                <li>Toque em <b>Mais opções</b> ou <b>Configurações</b> e selecione Dispositivos conectados.</li>
                                <li>Toque em <b>Conectar com número de telefone</b> e insira o código exibido acima.</li>
                            </ol>
                            <ol v-else class="mt-4">
                                <li>Abra o WhatsApp no seu celular.</li>
                                <li>Toque em <b>Mais opções</b> ou <b>Configurações</b> e selecione Aparelhos conectados.</li>
                                <li>Aponte seu celular para esta tela para capturar o código.</li>
                            </ol>
                        </div>
                    </div>
                </v-list>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'QrCodeWhatsapp',

    data: () => ({
        pairingCodeLoading: false,
        qrCodeLoading: false,
        connectionLoading: false,
        refreshPairingCodeLoading: false,
        wppInstanceKey: '',
        wppConected: false,
        wppQrBase64: '',
        pairingCode: null,
        open: false,
        interval: null,
        user: null,
        connectType: 'qrcode', //pairingcode
    }),

    mounted() {
        this.infoWppInstance();
        this.ws();
    },

    beforeDestroy() {
        document.removeEventListener('ws-qrcode', this.setWppQrCode);
        document.removeEventListener('ws-open', this.infoWppInstance);
        this.stopQrCodeRefresh();
    },

    watch: {
        open(v) {
            if (!v) {
                this.stopQrCodeRefresh();
            }

            if (!this.wppConected && this.wppInstanceKey && this.connectType === 'qrcode') {
                this.startQrCodeRefresh();
            }

            !this.wppConected && (!this.wppInstanceKey || !this.wppQrBase64) && this.connectWpp();
        },
    },

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
        ]),

        getIconColor() {
            if (this.connectionLoading) {
                return '#707070';
            }

            if (!this.wppConected) {
                return 'error';
            }

            return this.configuracoes?.whatsapp_bot_active ? 'success' : 'warning';
        },

        userName() {
            return this.user?.name || '';
        },

        userWhatsapp() {
            return this.user?.id ? this.user?.id.split(':')[0] : '';
        },

        whatsappBotActive: {
            get() {
                return this.configuracoes.whatsapp_bot_active;
            },

            set(value) {
                this.setConfig({ option: 'whatsapp_bot_active', value });
                this.save();
            },
        },
    },

    methods: {
        ...mapMutations({
            setConfig: 'setConfig',
        }),

        connectWpp() {
            this.wppInstanceKey
                ? this.qrbase64WppInstance()
                : this.initWppInstance();
        },

        async infoWppInstance() {
            this.connectionLoading = true;
            const { data } = (await this.$http.get('whatsapp-instance/info')).data;
            this.connectionLoading = false;

            this.wppInstanceKey = data?.instance_data?.instance_key || null;
            this.wppConected = data?.instance_data?.phone_connected;
            this.user = data?.instance_data?.user;

            setTimeout(() => this.showConnectWhatsappDialog(), 3000);
        },

        async showConnectWhatsappDialog() {
            const createdAt = this.moment(this.configuracoes.created_at);
            const today = this.moment();
            const thirtyDaysAgo = today.subtract(30, 'days');
            const isMoreThan30DaysAgo = createdAt.isBefore(thirtyDaysAgo);

            const dataAtual = new Date();
            const dataUltimaExecucao = new Date(localStorage.getItem('dateConnectWppDialog'));
            const basicConfigFinished = this.configuracoes.cep !== '-';

            const showConfirmDialog =  (
                !dataUltimaExecucao || dataAtual.getDate() !== dataUltimaExecucao.getDate()
            ) && !isMoreThan30DaysAgo && !this.wppConected && basicConfigFinished;

            if (showConfirmDialog) {
                const confirm = await this.$root.$confirm(
                    'Ative o Robô de Atendimento',
                    `Conecte o whatsapp do seu estabelecimento, para que o robô responda seus clientes. DESEJA ATIVAR AGORA?`,
                    { color: 'success' }
                );

                if (confirm) {
                    this.$refs.wppButton.$el.click();
                    this.connectWpp();
                }

                localStorage.setItem('dateConnectWppDialog', dataAtual);
            }
        },

        async initWppInstance() {
            this.connectionLoading = true;
            const { data } = (await this.$http.get('whatsapp-instance/init')).data;
            this.connectionLoading = false;

            if (!data.key) {
                return;
            }

            this.connectType = 'qrcode';

            this.wppInstanceKey = data.key;
        },

        async qrbase64WppInstance() {
            if (!this.open) {
                this.stopQrCodeRefresh();
                return;
            }
            this.qrCodeLoading = true;
            this.$http.get(`whatsapp-instance/qrbase64?key=${this.wppInstanceKey}`).then(async resp => {
                if (!resp.data.data.qrcode?.trim()) {
                    await this.resetInstance();
                    return;
                }

                this.wppQrBase64 = resp.data.data.qrcode;
            }).finally(() => (this.qrCodeLoading = false));
        },

        conectWithQrCode() {
            this.connectType = 'qrcode';
            this.pairingCode = null;
            this.connectWpp();
        },

        async connectWithPairingCode() {
            this.stopQrCodeRefresh();

            this.connectType = 'pairingcode';
            this.pairingCodeLoading = true;
            await this.$http.get(`whatsapp-instance/pairing-code?key=${this.wppInstanceKey}`).then(async resp => {
                 if (!resp.data.data.code) {
                    await this.resetInstance();
                    return;
                }
                this.pairingCode = resp.data.data.code;
            }).finally(() => (this.pairingCodeLoading = false));
        },

        async refreshPairingCode() {
            this.refreshPairingCodeLoading = true;
            await this.resetInstance();
            await this.connectWithPairingCode();
            this.refreshPairingCodeLoading = false;
        },

        startQrCodeRefresh() {
            this.counter = 0;
            clearInterval(this.interval);

            this.interval = setInterval(() => {
                if (this.counter >= 20 || this.wppConected) {
                    clearInterval(this.interval);
                    return;
                }

                this.qrbase64WppInstance();
                this.counter++;
            }, 10000);
        },

        stopQrCodeRefresh() {
            clearInterval(this.interval);
            this.counter = 0;
        },

        async deleteWppInstance() {
            await this.$http.delete(`whatsapp-instance/delete?key=${this.wppInstanceKey}`).then(() => {
                this.wppInstanceKey = '';
                this.wppConected = false;
            });
        },

        async resetInstance() {
            await this.deleteWppInstance();
            await this.initWppInstance();
        },

        ws() {
            document.addEventListener('ws-qrcode', this.setWppQrCode);
            document.addEventListener('ws-open', this.infoWppInstance);
        },

        setWppQrCode(event) {
            this.wppQrBase64 = event.detail;
        },

        save() {
            this.connectionLoading = true;
            const data = { whatsapp_bot_active: this.configuracoes.whatsapp_bot_active };
            this.$http.post('configuracoes/set-config', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.notify('Atualizado com sucesso!');
                })
                .catch(() => this.notify('Não foi possivel atualizar', 'warning'))
                .finally(() => (this.connectionLoading = false));
        },
    },
}
</script>
